<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Activation de la Skill
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container v-if="stepDisplay == 2">
      <div class="page-loader page-loader-logo align-middle p-5 m-5 col-12">
        <div class="align-content-center align-center text-center pt--50">
          <div
            class="text-center align-content-center"
            style="min-height: 300px"
          >
            <v-col>
              <h4>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                Vérification de l'activation
              </h4>
            </v-col>
          </div>
        </div>
      </div>
    </v-container>
    <v-container v-if="stepDisplay == 1">
      <div class="align-content-center align-center text-center pt--50">
        <div class="text-center">
          <v-col>
            <h4>
              Activation encours.
            </h4>
          </v-col>
          <v-col class="py-10"></v-col>
          <v-col class="pt-10">
            <h6>
              Vous pouvez aussi activer la Skill depuis l'application Alexa.
            </h6>
          </v-col>
        </div>
      </div>
    </v-container>
    <v-container v-if="stepDisplay == 3">
      <div class="align-content-center align-center text-center pt--50">
        <div class="text-center">
          <v-col>
            <h4>
              Votre compte Mes amis de confiance a été correctement associé.
            </h4>
          </v-col>
          <v-col class="py-10">
            <v-btn
              type="button"
              class="btn-custom mx-5 px-10"
              variant="outline-primary"
              style="background-color: white !important; color: red !important"
              :loading="isLoading"
              :disabled="isLoading"
            >
              <router-link to="/">
                Accueil
              </router-link>
            </v-btn>
          </v-col>

          <v-col class="pt-10">
            <h6></h6>
          </v-col>
        </div>
      </div>
    </v-container>
    <v-container v-if="stepDisplay == 4">
      <div class="align-content-center align-center text-center pt--50">
        <div class="text-center">
          <v-col>
            <h4>
              Une erreur est survenue pendant l'activation.<br />
              Recommencer ultérieurement.
            </h4>
          </v-col>
          <v-col class="py-10">
            <v-btn
              type="button"
              class="btn-custom mx-5 px-10"
              variant="outline-primary"
              style="background-color: white !important; color: red !important"
              :loading="isLoading"
              :disabled="isLoading"
            >
              <router-link to="/">
                Accueil
              </router-link>
            </v-btn>
          </v-col>
          <v-col class="pt-10">
            <h6>
              Vous pouvez aussi activer la Skill depuis l'application Alexa.
            </h6>
          </v-col>
        </div>
      </div>
    </v-container>
  </div>

  <!-- end::Page Loader -->
</template>

<script>
import API from "@aws-amplify/api";

export default {
  name: "alexalink",
  components: {},
  data() {
    return {
      isLoading: false,
      isLinking: false,
      stepDisplay: 2
    };
  },
  methods: {},
  async mounted() {
    if (
      this.$route.query.code !== undefined &&
      this.$route.query.state !== undefined &&
      this.$route.query.scope === undefined
    ) {
      this.stepDisplay = 2;
      const expireDate = new Date();
      expireDate.setTime(expireDate.getTime() + 4 * 60 * 1000);
      this.$cookies.set("authCodeLink", this.$route.query.code, {
        expires: expireDate
      });
      this.$cookies.set("authStateLink", this.$route.query.state, {
        expires: expireDate
      });
      document.location.href =
        "https://www.amazon.com/ap/oa?client_id=" +
        process.env.VUE_APP_ALEXA_CLIENT_ID +
        "&scope=alexa::skills:account_linking&response_type=code&redirect_uri=https://" +
        document.domain +
        "/user/alexalink&state=live";
      return;
    }

    if (
      this.$route.query.code &&
      this.$route.query.state &&
      this.$route.query.scope === "alexa::skills:account_linking"
    ) {
      this.stepDisplay = 2;
      const authCodeLink = this.$cookies.get("authCodeLink");
      this.$cookies.remove("authCodeLink");
      const result = await API.put(
        "teleassistancesenioruserapi321",
        "/alexa/activate",
        {
          queryStringParameters: {
            code: this.$route.query.code,
            state: this.$route.query.state,
            authCodeLink: authCodeLink,
            authStateLink: this.$cookies.get("authStateLink")
          }
        }
      );
      if (result.status !== 1) {
        this.stepDisplay = 4;
      } else {
        this.stepDisplay = 3;
      }
    } else {
      this.$router.push({ name: "activateapp" });
    }
  },
  computed: {}
};
</script>
